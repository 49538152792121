/* eslint-disable no-underscore-dangle */
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import { User, PublicUser, SurveyProperty, UserRole } from '@/api';

import store from '@/store';
import Vue from 'vue';

import { memoize } from 'lodash';

// this is pretty cool...
// can probably refactor this module to use this more effectively
const getUserByIdCache = memoize((id: string) => User.find(id));

const getPropertyBySiteIdCache = memoize((id: string) =>
  SurveyProperty.includes(['surveySites'])
    .where({ survey_sites__in: [id] })
    .first(),
);

// TODO: rename this / incorporate into the cache module
// I could also remove the property module because I don't think
// we need it the way we used to

// TODO: add a throttle / debounce so that we
// dont make multiple requests for the same thing

@Module({ dynamic: true, namespaced: true, name: 'user', store })
class UserModule extends VuexModule {
  _mappedUsers: {
    [id: string]: {
      id: string;
      name: string;
      avatarUrl: string;
      isPublic: boolean;
      isAdmin: boolean;
      isExpert: boolean;
    };
  } = {};

  _mappedProperties: {
    [id: string]: SurveyProperty;
  } = {};

  get userById() {
    return (id: string) => this._mappedUsers[id];
  }

  get propertyById() {
    return (id: string) => this._mappedProperties[id];
  }

  get propertyBySiteId() {
    return (id: string) =>
      Object.values(this._mappedProperties).find(
        p => !!p.surveySites.find(s => s.id === id),
      );
  }

  // mutations
  @Mutation
  setUser(user: User | PublicUser) {
    const roles = (user as User).roles || [];

    Vue.set(this._mappedUsers, user.id as string, {
      id: user.id as string,
      name: user.name,
      avatarUrl: user.avatarUrl,
      isPublic: user.isPublic,
      isAdmin: [UserRole.admin, UserRole.manager].some(role =>
        roles.includes(role),
      ),
      isExpert: [UserRole.admin, UserRole.manager, UserRole.expert].some(role =>
        roles.includes(role),
      ),
    });
  }

  @Mutation
  setProperty(property: SurveyProperty) {
    Vue.set(this._mappedProperties, property.id as string, property);
  }

  // actions
  @Action({ rawError: true })
  async getUser(id: string) {
    const existing = this.userById(id);
    if (existing) {
      return existing;
    }
    const user = (await getUserByIdCache(id)).data;
    this.setUser(user);
    return user;
  }

  @Action({ rawError: true })
  async getPropertyBySiteId(id: string) {
    const existing = this.propertyBySiteId(id);
    if (existing) {
      return existing;
    }
    const property = (await getPropertyBySiteIdCache(id)).data;
    if (property) {
      this.setProperty(property);
    }
    return property;
  }
}

export default getModule(UserModule, store);
