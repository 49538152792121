













































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardInfo from '@/components/common/CardInfo.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';

import authModule from '@/store/Auth';

import { SurveyProperty } from '@/api';
import { numberFormat } from '@/util';

@Component({
  components: {
    UserAvatar,
    CardInfo,
  },
})
export default class PropertyCard extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get owner() {
    return this.property.owner;
  }

  get isOwner() {
    return (
      authModule.user && this.owner && authModule.user.id === this.owner.id
    );
  }

  get numberFormat() {
    return numberFormat;
  }

  get canCreateSites() {
    return authModule.isAdmin || this.isOwner;
  }

  get canCreateSurveys() {
    return authModule.isAdmin || authModule.canCreateSurveys(this.property);
  }

  get canCreateUsers() {
    return authModule.isAdmin || this.isOwner;
  }
}
