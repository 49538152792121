










































































































































import { Component, Vue } from 'vue-property-decorator';

import { FaunaMedia, FaunaMediaTag } from '@/api';

import authModule from '@/store/Auth';

import PageHeader from '@/components/common/PageHeader.vue';
import AvatarUpload from '@/components/common/AvatarUpload.vue';

import AccountCard from '@/components/dashboard/AccountCard.vue';
import SidebarInfo from '@/components/common/SidebarInfo.vue';
import SidebarTiles from '@/components/common/SidebarTiles.vue';

import LeaderboardStatsLines from '@/components/property/LeaderboardStatsLines.vue';
import FaunaLeaderboardStatsLines from '@/components/property/FaunaLeaderboardStatsLines.vue';
import { numberFormat } from '@/util';

@Component({
  components: {
    PageHeader,
    AccountCard,
    AvatarUpload,
    LeaderboardStatsLines,
    FaunaLeaderboardStatsLines,
    SidebarInfo,
    SidebarTiles,
  },
})
export default class Dashboard extends Vue {
  get user() {
    return authModule.user;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isExpert() {
    return authModule.isExpert;
  }

  get roles() {
    return this.user
      ? this.user.roles.map(role => role.substr(0, role.length - 1)).join(' / ')
      : '';
  }

  tagCount = 0;

  imgCount = 0;

  get numberFormat() {
    return numberFormat;
  }

  get propertyCount() {
    return authModule.myProperties.length;
  }

  async changePrivacy() {
    authModule.setShowWelcomeDialog(true);
  }

  async getStats() {
    if (!authModule.user) {
      console.warn('need a user to get stats');
      return;
    }
    const [tags, imgs] = await Promise.all([
      FaunaMediaTag.where({
        created_by: authModule.user.id,
      }).first(),
      FaunaMedia.where({
        created_by: authModule.user.id,
      }).first(),
    ]);

    this.tagCount = tags ? tags.meta.pagination.count : 0;
    this.imgCount = imgs ? imgs.meta.pagination.count : 0;
  }

  mounted() {
    this.getStats();
  }
}
